import React from "react";
import { useEngContactData } from "lang/en/contact";
import { ContactLayout } from "sections/Contact/Contact";

const Contact = () => {
  const data = useEngContactData();
  return <ContactLayout data={data} />;
};

export default Contact;
